<template>
    <BaseUiPopup
        v-model="modelValue"
        :class="['sim-popup', {
            'slide-up': transition === 'slide-up',
            [`sim-popup--s-${size}`]: size !== 'md',
            'sim-popup--sharp': variant === 'sharp',
        }]"
        :reference="anchor"
        :placement="placement"
        :offset="offset"
        :arrow="variant !== 'sharp' ? { padding: 12 } : undefined"
        :ignore-elements="[anchor]"
        :match-min-reference-width="matchMinAnchorWidth"
    >
        <template v-if="!noTip" #arrow>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                :width="svgStyles.width"
                :height="svgStyles.height"
                :viewBox="svgStyles.viewBox"
                :class="['sim-popup__tip', tipClass]"
                v-bind="{...parentScope}"
            >
                <polygon :points="svgStyles.points" />
            </svg>
        </template>


        <div class="sim-popup__container" :class="containerClass" v-bind="{...scopeId}">
            <div :id="id" class="sim-popup__content">
                <slot />
            </div>
        </div>
    </BaseUiPopup>
</template>

<script lang="ts" setup>
import { SymbolUiPopupCard, type UiPopupCardProvide } from '@theme-types/components/UiPopupCard'
import type { BaseUiPopupProps } from '@core/app/components/base/ui/popup/BaseUiPopup.vue'

const {
    id,
    anchor,
    transition = 'slide-down',
    noTip,
    size = 'md',
    tipClass,
    containerClass,
    variant,
    placement,
    offset,
    matchMinAnchorWidth,
} = defineProps<{
    id?: string
    anchor: HTMLElement
    transition?: 'slide-up' | 'slide-down'
    noTip?: boolean
    size?: 'sm' | 'md'
    tipClass?: string
    containerClass?: string
    variant?: 'default' | 'sharp'
    placement?: BaseUiPopupProps['placement']
    offset?: BaseUiPopupProps['offset']
    matchMinAnchorWidth?: BaseUiPopupProps['matchMinReferenceWidth']
}>()

const parentScope = getParentScopeIdAttr()
const scopeId = getScopeIdAttr()

const modelValue = defineModel<boolean>()

const svgStyles = computed(() => {
    if (variant === 'sharp') {
        return {
            width: 20,
            height: 10,
            viewBox: '0 0 20 15',
            points: '3,3 20,20 3,20',
        }
    }

    return {
        width: 20,
        height: 10,
        viewBox: '0 0 20 10',
        points: '10,0 20,10 0,10',
    }
})

provide<UiPopupCardProvide>(SymbolUiPopupCard, {
    closePopup: () => {
        modelValue.value = false
    },
})

</script>

<style lang="scss" scoped>

// ANIMATION
.sim-popup {
    filter: drop-shadow(0 9px 19px rgb(8 15 61 / 15%)); // cannot be on an element with overflow: hidden (safari iOS issue)

    &.v-enter-active,
    &.v-leave-active {
        transition: opacity $sim-trans-time-short $sim-timing, transform $sim-trans-time-normal $sim-timing;
    }

    &.v-enter-from,
    &.v-leave-to {
        opacity: 0;
        transform: translateY(-0.5rem);
    }
}

.slide-up {
    &.v-enter-from,
    &.v-leave-to {
        transform: translateY(0.5rem);
    }
}

// CONTAINER
.sim-popup__container {
    max-height: inherit;
    max-width: inherit;
    overflow: hidden;
    width: 100%;

    border-radius: 0.5rem;

    background-color: $sim-c-white;
}

.sim-popup--sharp .sim-popup__container {
    border-radius: 0.125rem;
}

// CONTENT
.sim-popup__content {
    max-height: inherit;
    box-sizing: border-box;
    overflow-y: auto;

    padding: 1.25rem 1.5rem;
}

.sim-popup--s-sm .sim-popup__content {
    padding: 0.65rem 1rem;
}

.sim-popup--sharp .sim-popup__content {
    padding: 0.75rem 0.5rem;
}

// TIP
.sim-popup__tip {
    fill: $sim-c-white;
}

.sim-popup--sharp .sim-popup__tip {
    stroke-linejoin: round;
    stroke: $sim-c-white;
    stroke-width: 0.125rem;
    transform: translateX(20%);
}

</style>
